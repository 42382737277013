var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns is-multiline has-padding-50 is-marginless"},[_c('div',{staticClass:"column is-4-widescreen is-12-mobile is-12-tablet"},[_c('card',[_c('template',{slot:"header"},[_c('p',{staticClass:"card-header-title"},[_c('strong',[_vm._v("Product")])])]),_c('template',{slot:"body"},[_c('product-details',{ref:"productDetails",attrs:{"product-id":_vm.productId},on:{"processing":function($event){_vm.details.processing = $event},"valid":function($event){_vm.details.isValid = $event},"loading":function($event){_vm.details.loading = $event},"hasChange":function($event){_vm.details.hasChange = $event}}})],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"has-padding-75 is-stretched has-text-right"},[_c('button',{staticClass:"button is-success",class:{ 'is-loading': _vm.details.processing },attrs:{"disabled":!_vm.details.isValid ||
                _vm.details.loading ||
                _vm.details.processing ||
                !_vm.details.hasChange,"type":"submit"},on:{"click":function($event){return _vm.$refs.productDetails.saveProduct()}}},[_vm._v(" Save ")])])])],2)],1),_c('div',{staticClass:"column is-8-widescreen is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline"},[(_vm.product && _vm.product.type === 'subscription')?_c('div',{staticClass:"column is-12"},[_c('card',[_c('template',{slot:"header"},[_c('p',{staticClass:"card-header-title"},[_c('strong',[_vm._v("Provision")])])]),_c('template',{slot:"body"},[_c('product-provisions',{ref:"provision",attrs:{"product-id":_vm.productId},on:{"processing":function($event){_vm.provision.processing = $event},"valid":function($event){_vm.provision.isValid = $event},"loading":function($event){_vm.provision.loading = $event},"hasChange":function($event){_vm.provision.hasChange = $event}}})],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"has-padding-75 is-stretched has-text-right"},[_c('button',{staticClass:"button is-success",class:{ 'is-loading': _vm.provision.processing },attrs:{"disabled":!_vm.provision.isValid ||
                    _vm.provision.loading ||
                    _vm.provision.processing ||
                    !_vm.provision.hasChange,"type":"submit"},on:{"click":function($event){return _vm.$refs.provision.saveProduct()}}},[_vm._v(" Save ")])])])],2)],1):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('card',[_c('template',{slot:"header"},[_c('p',{staticClass:"card-header-title"},[_c('strong',[_vm._v("Terms")])])]),_c('template',{slot:"body"},[_c('product-terms-table',{attrs:{"product-id":_vm.productId}})],1)],2)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }