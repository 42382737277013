<template>
  <div class="columns is-multiline has-padding-50 is-marginless">
    <div class="column is-4-widescreen is-12-mobile is-12-tablet">
      <card>
        <template slot="header">
          <p class="card-header-title">
            <strong>Product</strong>
          </p>
        </template>
        <template slot="body">
          <product-details
            ref="productDetails"
            :product-id="productId"
            @processing="details.processing = $event"
            @valid="details.isValid = $event"
            @loading="details.loading = $event"
            @hasChange="details.hasChange = $event"
          />
        </template>

        <template slot="footer">
          <div class="has-padding-75 is-stretched has-text-right">
            <button
              :class="{ 'is-loading': details.processing }"
              :disabled="
                !details.isValid ||
                  details.loading ||
                  details.processing ||
                  !details.hasChange
              "
              type="submit"
              class="button is-success"
              @click="$refs.productDetails.saveProduct()"
            >
              Save
            </button>
          </div>
        </template>
      </card>
    </div>

    <div class="column is-8-widescreen is-12-mobile is-12-tablet">
      <div class="columns is-multiline">
        <div
          v-if="product && product.type === 'subscription'"
          class="column is-12"
        >
          <card>
            <template slot="header">
              <p class="card-header-title">
                <strong>Provision</strong>
              </p>
            </template>

            <template slot="body">
              <product-provisions
                ref="provision"
                :product-id="productId"
                @processing="provision.processing = $event"
                @valid="provision.isValid = $event"
                @loading="provision.loading = $event"
                @hasChange="provision.hasChange = $event"
              />
            </template>

            <template slot="footer">
              <div class="has-padding-75 is-stretched has-text-right">
                <button
                  :class="{ 'is-loading': provision.processing }"
                  :disabled="
                    !provision.isValid ||
                      provision.loading ||
                      provision.processing ||
                      !provision.hasChange
                  "
                  type="submit"
                  class="button is-success"
                  @click="$refs.provision.saveProduct()"
                >
                  Save
                </button>
              </div>
            </template>
          </card>
        </div>

        <div class="column is-12">
          <card>
            <template slot="header">
              <p class="card-header-title">
                <strong>Terms</strong>
              </p>
            </template>
            <template slot="body">
              <product-terms-table :product-id="productId" />
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Product",
  components: {
    "product-details": () => import("@shared/product/_productDetails"),
    "product-provisions": () => import("@shared/product/_productProvisions"),
    "product-terms-table": () => import("@shared/product/_productTermsTable")
  },
  data() {
    return {
      productId: this.$route.params.productId,
      details: {
        loading: false,
        processing: false,
        isValid: false,
        hasChange: true
      },
      provision: {
        loading: false,
        processing: false,
        isValid: false,
        hasChange: true
      }
    };
  },
  computed: {
    product() {
      return this.$store.getters["products/product"](this.productId);
    }
  },
  created() {
    this.$store.dispatch("products/observeProduct", {
      productId: this.productId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("products/unobserveProduct", {
      productId: this.productId
    });
  }
};
</script>
